import { useEffect } from "react"
import { addPropertyControls, ControlType } from "framer"

declare global {
    interface Window {
        checkoutPage: any
    }
}

function useEmbed() {
    useEffect(() => {
        if (window?.checkoutPage) return

        const script = document.createElement("script")

        script.src = "https://checkoutpage.co/js/overlay.js"
        script.async = true

        document.body.appendChild(script)

        return () => {
            document.body.removeChild(script)
        }
    }, [])

    return null
}

export default function CheckoutEmbed({
    checkout,
    style,
}: {
    checkout: String
    style: Object
}) {
    useEmbed()

    useEffect(() => {
        window?.checkoutPage?.initEmbeds()
    }, [checkout])

    return (
        <div style={style}>
            <div className="cp-checkout" data-checkout={checkout}></div>
        </div>
    )
}

// Add a form id field in the properties panel
addPropertyControls(CheckoutEmbed, {
    checkout: {
        title: "Checkout",
        type: ControlType.String,
        placeholder: "Your checkout link",
        defaultValue:
            "https://checkoutpage.co/c/examples/customize-this-checkout",
    },
})
